// react + router
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// components
import NavBar from "./components/NavBar";

// pages
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";

// stylesheets
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Fishtank from "./pages/Fishtank";
import StreamView from "./pages/StreamView";

ReactDOM.createRoot(document.getElementById("root")).render(
  <Router>
    <NavBar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/live/:uriName" element={<StreamView />} />
      <Route path="/streams/:uriName" element={<StreamView />} />
      <Route path="/fishtank" element={<Fishtank />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
);
