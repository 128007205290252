export default function Footer() {
  return (
    <footer className="container-fluid d-flex flex-column flex-lg-row justify-content-lg-between align-items-center py-2 border-top mt-5">
      <p className="mb-2 mb-lg-0 text-body-secondary text-center text-lg-start">
        FlowStreams {new Date().getFullYear()}
      </p>
      <ul className="nav justify-content-center justify-content-lg-end mb-0">
        <li className="nav-item">
          <a
            href="https://blacked.com"
            className="nav-link px-2 text-body-secondary"
          >
            BLACKED.COM
          </a>
        </li>
      </ul>
    </footer>
  );
}
