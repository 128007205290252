import { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import JWPlayer from "@jwplayer/jwplayer-react";
import $ from "jquery";
import "./streamview.css";
import Footer from "../components/Footer";
export default function StreamView() {
  const { uriName } = useParams();
  const streamName = uriName.replaceAll("/", " ").replaceAll("-", " ");
  const playlist = "https://tubals.tech/video.mp4";
  const layout = (type) => {
    if (type === "theatre") {
      $("#container").css("max-width", "100%");
      $("#container").removeClass("mt-4");
      $("#chat-col").css("padding-left", "6px");
      $("#video-col").css("padding-right", "6px");
      $("#tc-message-cont").css("height", "38.6vw");
      //$('body').css('background-color', '#0A0A0A');
      $("body").css("background-color", "#000000");
      $("#chat-col").css("width", "20.2%");
      $("#title").hide();
      $("#viewersparent").hide();
      $("footer").removeClass("mt-5").addClass("mt-0");
    }
    if (type === "normal") {
      $("#container").css("max-width", "1542px");
      $("#container").addClass("mt-4");
      $("#chat-col").css("padding-left", "12px");
      $("#video-col").css("padding-right", "12px");
      $("#tc-message-cont").css("height", "27.8vw");
      $("body").css("background-color", "#0e0e0e");
      $("#chat-col").css("width", "25%");
      $("#title").show();
      $("#viewersparent").show();
      $("footer").removeClass("mt-0").addClass("mt-5");
    }
  };
  const layoutswitch = () => {
    // eslint-disable-next-line no-undef
    const tmo = getCookie("theatre_mode");
    if (tmo !== "true") {
      document.cookie =
        "theatre_mode=true; expires=Tue, 01 Jan 2030 12:00:00 UTC";
      layout("theatre");
    } else {
      document.cookie =
        "theatre_mode=false; expires=Tue, 01 Jan 2030 12:00:00 UTC";
      layout("normal");
    }
  };
  useEffect(() => {
    document.title = `Watch ${streamName} for FREE on ${document.location.hostname}`;
    // eslint-disable-next-line no-undef
    const tmo = getCookie("theatre_mode");
    if (tmo === "true") {
      // eslint-disable-next-line no-undef
      layout("theatre");
    } else {
      // eslint-disable-next-line no-undef
      layout("normal");
    }
    // eslint-disable-next-line no-undef
    return () => layout("normal");
    // eslint-disable-next-line no-undef
  }, [streamName]);
  /** @param {import("@jwplayer/jwplayer-react").DidMountCallbackArguments} param0 */
  const mountCallback = ({ id, player }) => {
    player.addButton(
      `${process.env.PUBLIC_URL}/assets/theatre.svg`,
      "Theatre mode",
      (err, result) => {
        // eslint-disable-next-line no-undef
        layoutswitch();
      },
      "layout_btn"
    );
    player.on("error", () => {
      const interval = setInterval(async () => {
        // eslint-disable-next-line no-undef
        if (await checkLiveStatus(playlist)) {
          clearInterval(interval);
          player.load({ file: playlist });
          player.play();
        }
      }, 5000);
    });
    player.on("firstFrame", () => {
      player.setVolume(100);
      player.setFullscreen(true);
      fetch("/api/alert", {
        method: "POST",
        body: "1",
      });
    });
  };
  return (
    <Container
      fluid
      style={{ maxWidth: "1542px" }}
      className="pt-0 text-white mt-4"
      id="container"
    >
      <Row>
        <Col id="video-col">
          <div>
            <JWPlayer
              library={`${process.env.PUBLIC_URL}/assets/js/jwplayer.js`}
              title={streamName}
              width="100%"
              height="720"
              aspectratio="16:9"
              responsive={true}
              repeat={true}
              displayTitle={true}
              playlist={{
                file: playlist,
              }}
              cast={{}}
              didMountCallback={mountCallback}
            />
          </div>
          <div className="d-flex justify-content-between align-items-end mt-3">
            <h3 className="my-auto" id="title">
              {streamName}
            </h3>
          </div>
          <p className="mt-2" id="viewersparent">
            <span id="viewers">1</span> viewers
          </p>
        </Col>
        <Col xl="3" className="chat-width" id="chat-col">
          <Card bg="darker" className="rounded-0">
            <CardHeader
              as="h5"
              className="d-flex justify-content-between align-items-end"
            >
              <span>FlowStreams Chat</span>
              <span
                className="my-auto text-muted"
                style={{ fontSize: "small" }}
              >
                not connected <span className="text-secondary"> ●</span>
              </span>
            </CardHeader>
            <CardBody className="p-0">
              <div className="tc-message-cont" id="tc-message-cont">
                <div className="message-list" id="message-list" />
                <div className="emote-picker-container">
                  <div className="emote-picker" id="emote-picker">
                    <div className="emote-container" id="emote-container">
                      <h6 className="emote-section-heading">All emotes</h6>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
            <div className="tc-message-box">
              <form className="w-100 message-form" id="message-form">
                <div className="input-group">
                  {" "}
                  <textarea
                    type="text"
                    className="tc-message-input col-12 form-control rounded comment-name message-input-item"
                    id="message-input"
                    placeholder="Send a message"
                  />
                  <button
                    id="emotes"
                    className="input-group-text chat-button message-input-item"
                  >
                    <img
                      style={{ verticalAlign: "top" }}
                      height="20px"
                      src={`${process.env.PUBLIC_URL}/assets/emote.svg`}
                      aria-hidden="true"
                      alt="Emotes"
                    />
                  </button>
                </div>
              </form>
            </div>
          </Card>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
}
