import { Container, Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export default function NavBar() {
  const location = useLocation();
  return (
    <Navbar expand="lg" bg="black">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          FlowStreams
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarSupportedContent" />
        <Navbar.Collapse id="navbarSupportedContent">
          <Nav className="me-auto">
            <Nav.Link
              as={Link}
              to="/fishtank"
              active={location.pathname === "/fishtank"}
            >
              Fishtank
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
