import { useEffect, useState } from "react";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import $ from "jquery";
export default function Fishtank() {
  const [streams, setStreams] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      document.title = `fishtank - ${document.location.hostname}`;
      if (!document.head.querySelector("#jwPlayerScript")) {
        const jwScript = document.createElement("script");
        jwScript.id = "jwPlayerScript";
        jwScript.src = `${process.env.PUBLIC_URL}/assets/js/jwplayer.js`;
        document.head.appendChild(jwScript);
      }
      let _streams = [];
      fetch(process.env.REACT_APP_STREAMS_API)
        .then((response) => response.json())
        .then((data) => {
          _streams = data.streams;
          setStreams(data.streams);
          init("lounge-3");
        })
        .catch(() => {
          alert("failed to fetch streams");
        });
      const loadStream = (stream) => {
        // eslint-disable-next-line no-undef
        const player = jwplayer("player").setup({
          title: stream.name,
          image: stream.thumbnail,
          aspectratio: "16:9",
          width: "100%",
          repeat: true,
          responsive: true,
          playlist: [{ file: stream.playlist }],
          cast: {},
        });
        try {
          player.play();
        } catch {
          console.log("failed to autoplay");
        }
        player.on("error", () => {
          const interval = setInterval(async () => {
            // eslint-disable-next-line no-undef
            if (await checkLiveStatus(stream.playlist)) {
              clearInterval(interval);
              player.load({ file: stream.playlist });
              player.play();
            }
          }, 50000);
        });
      };

      const updateMute = () => {
        // eslint-disable-next-line no-undef
        if (getCookie("mutesfx") !== "1") {
          $("#mutesfx").text("mute sfx");
        } else {
          $("#mutesfx").text("unmute sfx");
        }
      };
      const init = (room) => {
        updateMute();
        let streamsLoaded = false;
        const interval = setInterval(() => {
          if ($(".cam").length >= _streams.length) {
            clearInterval(interval);
            streamsLoaded = true;
          }
        }, 100);
        $.when(streamsLoaded).then(() => {
          const blip = document.getElementById("blip");
          const click = document.getElementById("click");
          $(".cam")
            .on("mouseenter", () => {
              if (
                // eslint-disable-next-line no-undef
                getCookie("mutesfx") !== "1"
              ) {
                blip.pause();
                blip.currentTime = 0;
                blip.play().catch(() => {});
              }
            })
            .on("click", function (e) {
              e.preventDefault();
              // eslint-disable-next-line no-undef
              if (getCookie("mutesfx") !== "1") {
                click.pause();
                click.currentTime = 0;
                click.play().catch(() => {});
              }
              let id = $(this).attr("data-id");
              $(".cam").removeClass("border-light");
              $(this).addClass("border-light");
              if (id) loadStream(_streams.find((stream) => stream.id === id));
            });
          $("#mutesfx").on("click", (e) => {
            e.preventDefault();
            // eslint-disable-next-line no-undef
            if (getCookie("mutesfx") !== "1") {
              document.cookie =
                "mutesfx=1; expires=Mon, 18 Jan 2038 12:00:00 UTC; path=/";
            } else {
              document.cookie =
                "mutesfx=0; expires=Mon, 18 Jan 2038 12:00:00 UTC; path=/";
            }
            updateMute();
          });
          if (room) {
            const elements = $(`[data-id*="${room}"]`);
            if (elements.length === 1) {
              elements.each(function () {
                $(this).trigger("click");
              });
            }
          }
        });
      };
    }
  }, [streams, loaded]);
  return (
    <Container fluid className="pt-2 text-white">
      <Row className="align-items-start">
        <Col className="py-0 p-1">
          <video id="player" />
          <Row className="px-2 row-cols-2 justify-content-center">
            {streams
              .filter((stream) => stream.type === "season_pass")
              .map(({ id, name, thumbnail }) => (
                <Col xxl className="col-3 px-1 py-1" key={id}>
                  <Card
                    as="a"
                    href={`/fishtank/${id}`}
                    className="cam"
                    data-id={id}
                    draggable="false"
                  >
                    <Card.Img src={thumbnail} className="h-75" alt={name} />
                    <Card.ImgOverlay className="text-white px-2 p-1">
                      <Badge bg="" className="text-bg-dark fw-light">
                        {name}
                      </Badge>
                    </Card.ImgOverlay>
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
        <Col xl="3" className="px-0">
          <div className="text-center text-muted pt-0 pb-1">
            {/* eslint-disable-next-line */}
            <a
              id="mutesfx"
              href=""
              target="_blank"
              rel="noreferrer"
              className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
            >
              mute sfx
            </a>{" "}
            •{" "}
            <a
              href={process.env.REACT_APP_STREAMS_PLAYLIST}
              target="_blank"
              rel="noreferrer"
              className="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
            >
              playlist
            </a>
          </div>
          <Row id="normal_cams" className="m-0">
            {streams
              .filter((stream) => stream.type === "normal")
              .map(({ id, name, thumbnail }) => (
                <Col md="4" className="col-4 pe-1 ps-0 pt-1" key={id}>
                  <Card
                    as="a"
                    href={`/fishtank/${id}`}
                    className="cam"
                    data-id={id}
                    draggable="false"
                  >
                    <Card.Img
                      src={thumbnail}
                      className="h-100"
                      alt={name}
                      loading="lazy"
                    />
                    <Card.ImgOverlay className="text-white px-2 p-1">
                      <Badge bg="" className="text-bg-dark fw-light">
                        {name}
                      </Badge>
                    </Card.ImgOverlay>
                  </Card>
                </Col>
              ))}
          </Row>
          <div className="pe-1 pt-1">
            <iframe
              src={`https://www.twitch.tv/embed/${process.env.REACT_APP_TWITCH_USER}/chat?parent=${document.location.hostname}&darkpopout`}
              title="Chat"
              width="100%"
              style={{ height: "20vw" }}
            />
          </div>
        </Col>
      </Row>
      <audio id="blip" src="https://cdn.fishtank.live/sounds/blip.mp3" />
      <audio
        id="click"
        src="https://cdn.fishtank.live/sounds/chunk-short.mp3"
      />
    </Container>
  );
}
