import Footer from "../components/Footer";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
export default function NotFound() {
  useEffect(() => {
    document.title = `page not found - ${document.location.hostname}`;
  }, []);
  return (
    <Container fluid="md" className="pt-2 mt-5" bg="dark">
      <h1 className="text-center">This page was not found.</h1>
      <h5 className="text-center my-5">
        Options:{" "}
        <Link as={Link} to="/">
          homepage
        </Link>
      </h5>
      <Footer />
    </Container>
  );
}
