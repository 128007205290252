import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import { useEffect } from "react";
export default function Home() {
  useEffect(() => {
    document.title = `home - ${document.location.hostname}`;
  }, []);
  return (
    <Container fluid="md" className="pt-2 mt-5" bg="dark">
      <h1 className="text-center">Page under construction</h1>
      <Footer />
    </Container>
  );
}
